import React from "react"
import Layout from "../components/LayoutComponent"
import Seo from "../components/SeoComponent"
import { useStaticQuery, graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"
import { useIntl, FormattedMessage } from "gatsby-plugin-react-intl"
import "../assets/proofreader.css"

const Proofreader = () => {
  const { backgroundImage } = useStaticQuery(graphql`
    query {
      site {
        info: siteMetadata {
          description
          siteUrl
          author
        }
      }
      backgroundImage: file(relativePath: { eq: "LANDSCAPE_PROOFREADER.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
  `)
  const pluginImage = getImage(backgroundImage)
  const intl = useIntl()
  return (
    <Layout>
      <Seo
        title={intl.formatMessage({ id: "lektor" })}
        canonicalUrl={"proofreader"}
      />
      <BgImage image={pluginImage} className="bg-proof"></BgImage>
      <article className="text-proof">
        <h1>
          <FormattedMessage id="proof-page.proof-one" />
        </h1>
        <p>
          <FormattedMessage id="proof-page.proof-two" />
        </p>
        <p>
          <FormattedMessage id="proof-page.proof-three" />
        </p>
      </article>
    </Layout>
  )
}

export default Proofreader
